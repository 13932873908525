@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 0.3px;
}

/* Customizing the scrollbar */
::-webkit-scrollbar {
  width: 12px; /* Set width for vertical scrollbar */
}

::-webkit-scrollbar-track {
  background: rgb(50, 50, 50); /* Track color */
  border-radius: 10px; /* Optional: rounded corners for track */
}

::-webkit-scrollbar-thumb {
  background: rgb(100, 100, 100); /* Thumb color */
  border-radius: 5px; /* Optional: rounded corners for thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(70, 70, 70); /* Thumb color on hover */
}


a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

}
